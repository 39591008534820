import MapGL, { MapRef, Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Activity, ActivitySearchResult } from 'types';
import { Ref, useEffect, useMemo, useRef, useState } from 'react';
import bbox from '@turf/bbox';
import { points as getPoints } from '@turf/helpers';
import { useMantineTheme } from '@mantine/core';
import { MapPin } from '@phosphor-icons/react';

interface IMap {
  markers: Record<string, Activity[] | ActivitySearchResult[]>;
  centerLat: number;
  centerLng: number;
  zoom: number;
  onDragEnd?: () => void;
}

const Map: React.FC<IMap> = ({ markers, centerLat, centerLng, zoom, onDragEnd }) => {
  const theme = useMantineTheme();
  const mapRef: Ref<MapRef> = useRef(null);
  const [viewport, setViewport] = useState({
    latitude: centerLat,
    longitude: centerLng,
    zoom,
  });
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);

  const uniqueCoordinates = useMemo(() => {
    const keys = Object.keys(markers);
    if (keys.length === 0) {
      return undefined;
    }
    return keys.map((key) => key.split(':').map(Number));
  }, [markers]);

  const points = useMemo(() => {
    if (!uniqueCoordinates) {
      return undefined;
    }
    return getPoints(uniqueCoordinates);
  }, [uniqueCoordinates]);

  const bounds = useMemo(() => {
    if (!points) {
      return undefined;
    }
    return bbox(points);
  }, [points]);

  useEffect(() => {
    if (!bounds || !mapLoaded || !mapRef?.current) {
      return;
    }
    const [minLng, minLat, maxLng, maxLat] = bounds;

    mapRef?.current?.fitBounds(
      [
        [minLat, minLng],
        [maxLat, maxLng],
      ],
      { duration: 500, padding: { top: 50, bottom: 50, left: 25, right: 25 } },
    );
  }, [bounds, uniqueCoordinates, mapLoaded]);

  const Markers = useMemo(() => {
    return uniqueCoordinates?.map(([lat, lng]) => {
      return (
        <Marker latitude={lat} longitude={lng} anchor="bottom" key={`${lat}:${lng}`}>
          <MapPin weight="fill" size={36} color={theme.colors.pink[5]} />
        </Marker>
      );
    });
  }, [uniqueCoordinates, theme]);

  return (
    <MapGL
      ref={mapRef}
      style={{
        height: '100%',
        width: '100%',
      }}
      mapStyle={process.env.NEXT_PUBLIC_MAPBOX_STYLES_URL}
      initialViewState={viewport}
      maxZoom={14}
      minZoom={2}
      mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_API_KEY}
      onLoad={() => setMapLoaded(true)}
      onDragEnd={() => onDragEnd?.()}
      onMove={(e) => {
        setViewport({
          ...e.viewState,
        });
      }}
    >
      {Markers}
    </MapGL>
  );
};

export default Map;
