import { useEffect, useMemo, useState } from 'react';
import { Accordion, useMantineTheme } from '@mantine/core';
import { ActivitySearchResult } from 'types';
import { NewActivityCard } from 'components';
import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './ActivitiesByLocationAccordion.module.scss';
import { CaretDown, MapPin, VideoCamera } from '@phosphor-icons/react';

type ActivitiesByLocation = {
  [key: string]: ActivitySearchResult[];
};

interface IActivitiesByLocationAccordionProps {
  activities: ActivitySearchResult[];
  supplierId: string;
}

const sortActivities = (groupedActivities: ActivitiesByLocation): ActivitiesByLocation => {
  const entries = [...Object.entries(groupedActivities)];
  return Object.fromEntries(
    entries.sort((a, b) => {
      // Online activities come last
      if (a[0] === 'online') {
        return 1;
      }
      if (b[0] === 'online') {
        return -1;
      }

      // then sorted by distance from search location
      const distanceA = a[1]?.[0]?.distanceInMiles;
      const distanceB = b[1]?.[0]?.distanceInMiles;
      if (distanceA || distanceB) {
        return distanceA - distanceB;
      }

      // then if no search location, sort alphabetically ignoring building numbers
      const location1 = a[1]?.[0]?.location ? a[1][0].location.replace(/[^a-zA-Z]+/, '') : '';
      const location2 = b[1]?.[0]?.location ? b[1][0].location.replace(/[^a-zA-Z]+/, '') : '';

      return location1.localeCompare(location2);
    }),
  );
};

const ActivitiesByLocationAccordion: React.FC<IActivitiesByLocationAccordionProps> = ({
  activities,
  supplierId,
}: IActivitiesByLocationAccordionProps) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const activitiesGroupedAndSortedByLocation =
    useMemo<ActivitiesByLocation>((): ActivitiesByLocation => {
      const grouped = activities.reduce(
        (accum: ActivitiesByLocation, curr: ActivitySearchResult) => {
          const key = curr.isOnline ? 'online' : `${curr.latitude}:${curr.longitude}`;
          const existing = accum[key] || [];
          return {
            ...accum,
            [key]: [...existing, curr],
          };
        },
        {},
      );
      const sorted = sortActivities(grouped);
      return sorted;
    }, [activities]);

  const [accordionValue, setAccordionValue] = useState<string[]>([]);

  useEffect(() => {
    const locationKeys = Object.keys(activitiesGroupedAndSortedByLocation);
    // if more than 3 venues, only expand the first accordion
    if (locationKeys.length > 3) {
      setAccordionValue([locationKeys[0]]);
    } else {
      setAccordionValue(locationKeys);
    }
  }, [activitiesGroupedAndSortedByLocation]);

  const handleAccordionChange = (val: string[]) => {
    // val will be an array or strings for each open section
    // we can compare the length of this array to the current state to determine if a section has been opened/closed.
    if (val.length > accordionValue.length) {
      trackAction(Actions.SUPPLIER_ACCORDION_EXPAND, { supplierId: supplierId });
    } else {
      trackAction(Actions.SUPPLIER_ACCORDION_COLLAPSE, { supplierId: supplierId });
    }
    setAccordionValue(val);
  };

  return (
    <Accordion
      value={accordionValue}
      onChange={handleAccordionChange}
      multiple
      classNames={{
        item: classes.item,
        label: classes.label,
        control: classes.control,
        panel: classes.panel,
        icon: classes.icon,
        content: classes.content,
        chevron: classes.chevron,
      }}
      mb={0}
      chevron={<CaretDown weight="bold" size={20} color={theme.colors.gray[8]} />}
    >
      {Object.entries(activitiesGroupedAndSortedByLocation).map(
        ([locationKey, groupedActivities]) => (
          <Accordion.Item value={locationKey} key={locationKey}>
            <Accordion.Control
              icon={
                locationKey === 'online' ? (
                  <VideoCamera size={18} weight="fill" color={theme.colors.blue[8]} />
                ) : (
                  <MapPin size={18} weight="fill" color={theme.colors.pink[5]} />
                )
              }
            >
              {groupedActivities?.[0]?.distanceInMiles ? (
                <span className={classes.distanceFromLabel}>
                  {groupedActivities?.[0]?.distanceInMiles}m
                </span>
              ) : (
                ''
              )}

              {locationKey === 'online' ? 'Online' : groupedActivities?.[0]?.location}
            </Accordion.Control>
            <Accordion.Panel>
              <Carousel
                // TODO: Indicators not showing correctly on first render since Mantine upgrade, hide for now and discuss with product
                // withIndicators={isMobile ? false : groupedActivities.length > 3}
                withControls={isMobile ? false : groupedActivities.length > 3}
                slideSize="auto"
                slideGap="sm"
                align="start"
                slidesToScroll={isMobile ? 1 : 3}
                draggable
                dragFree={isMobile}
                containScroll="keepSnaps"
                classNames={{
                  container: classes.carouselContainer,
                  viewport: classes.carouselViewport,
                  indicators: classes.carouselIndicators,
                  indicator: classes.carouselIndicator,
                  controls: classes.carouselControl,
                }}
              >
                {groupedActivities.map((activity) => (
                  <Carousel.Slide key={activity.id}>
                    <NewActivityCard activity={activity} key={activity.id} />
                  </Carousel.Slide>
                ))}
              </Carousel>
            </Accordion.Panel>
          </Accordion.Item>
        ),
      )}
    </Accordion>
  );
};

export default ActivitiesByLocationAccordion;
