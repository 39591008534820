import { Box, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Info } from '@phosphor-icons/react';
import classes from './SubsScheduleContent.module.scss';
import { Calendar } from '@mantine/dates';
import dayjs from 'dayjs';
import classNames from 'classnames';

interface ISubsScheduleContentProps {
  subName: string;
  subDescription: string;
  offDates: string[];
  weekdays: string[];
  startDate: string | null;
}
const SubsScheduleContent: React.FC<ISubsScheduleContentProps> = ({
  subName,
  subDescription,
  offDates,
  weekdays,
  startDate,
}) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery('(max-width: 768px)', true);

  const startDateAsDate = startDate ? new Date(startDate) : new Date();

  const getDayClass = (date: Date) => {
    if (offDates.includes(dayjs(date).format('YYYY-MM-DD'))) {
      return classes.offDates;
    }

    if (weekdays.some((day) => dayjs(date).format('dddd') === day)) {
      return classes.activityDays;
    }

    return '';
  };

  return (
    <>
      <Stack
        align="center"
        pt={isMobile ? '0' : '50px'}
        w={isMobile ? 'auto' : '593px'}
        m={'0 auto'}
        mb="lg"
      >
        <Info size={40} color={theme.colors.blue[5]} weight="duotone" />
        <Text c={theme.colors.blue[5]} fw={700} size="20px">
          Subscription Details / Schedule
        </Text>
        <Text ta="center" size="16px" lh="22px" color={theme.colors.blue[8]}>
          Sometimes providers have specific ways their subscriptions work and periods of time they
          don’t operate. We show all this information here.{' '}
        </Text>
      </Stack>

      <Box className={classes.subInfoWrapper} mb="lg">
        <Text fw={700} size="16px">
          {subName}
        </Text>
        <Text>
          <div
            className={classes.subDescription}
            dangerouslySetInnerHTML={{ __html: subDescription }}
          />
        </Text>
      </Box>

      <Box className={classes.calendarWrapper}>
        <div data-testid="subs-calendar">
          <Calendar
            classNames={{
              day: classes.day,
              calendarHeaderControl: classes.pagination,
              calendarHeaderLevel: classes.month,
              weekday: classNames(classes.dayOfWeek, {
                [classes.monBold]: weekdays.includes('Monday'),
                [classes.tueBold]: weekdays.includes('Tuesday'),
                [classes.wedBold]: weekdays.includes('Wednesday'),
                [classes.thuBold]: weekdays.includes('Thursday'),
                [classes.friBold]: weekdays.includes('Friday'),
                [classes.satBold]: weekdays.includes('Saturday'),
                [classes.sunBold]: weekdays.includes('Sunday'),
              }),
              levelsGroup: classes.levelsGroup,
            }}
            getDayProps={(date) => ({
              className: getDayClass(date),
            })}
            minDate={startDateAsDate}
            defaultDate={startDateAsDate}
            maxLevel="month"
            hideOutsideDates
            withCellSpacing={false}
            mb="lg"
            size="md"
          />
        </div>
      </Box>

      <div className={classes.calendarKey}>
        <div className={classes.flexKey}>
          <div className={classes.activeDateKey} />
          <Text size="sm" fw={600}>
            Active
          </Text>
        </div>
        <div className={classes.flexKey}>
          <div className={classes.offDaysKey} />
          <Text size="sm" fw={600}>
            Off-Days
          </Text>
        </div>
      </div>
    </>
  );
};

export default SubsScheduleContent;
